<script lang="ts">
import htmlFromConfig from "../components/menu/HtmlFromConfig.vue";

export default {
  name: 'MentionsLegales',
  setup() {
    useHead({
      title: 'Mentions Légales',
    })
  },
  components: {
    htmlFromConfig,
  },
}
</script>

<template>
  <html-from-config html-name="mention_legales" />
</template>


